// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-create-pages-templates-blog-list-tsx": () => import("./../../../src/create-pages/templates/blog-list.tsx" /* webpackChunkName: "component---src-create-pages-templates-blog-list-tsx" */),
  "component---src-create-pages-templates-blog-post-tsx": () => import("./../../../src/create-pages/templates/blog-post.tsx" /* webpackChunkName: "component---src-create-pages-templates-blog-post-tsx" */),
  "component---src-create-pages-templates-home-tsx": () => import("./../../../src/create-pages/templates/home.tsx" /* webpackChunkName: "component---src-create-pages-templates-home-tsx" */),
  "component---src-create-pages-templates-tag-tsx": () => import("./../../../src/create-pages/templates/tag.tsx" /* webpackChunkName: "component---src-create-pages-templates-tag-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

